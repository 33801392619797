import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';
import { updateProductAddon } from '../../../../../services/products';
import { pushSuccessfulNotification, pushDangerNotification } from '../../../../../data/notifications/actions';
import AddonValidationService from '../../../validation/AddonValidationService';
import { restAxios } from '../../../../../services/httpClient';
import { ModalBody } from './ModalBody';
import { getJob } from '../../../../../services/jobs';

const FORM_NAME = 'Addon';

const EditModal = (props) => {
	const { onHide, afterUpdateCallback, pushSuccessfulNotification, pushDangerNotification, initialValues, editedAddon } = props;
	const { handleSubmit, change } = props;
	const [versions, setVersions] = useState([]);
	const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
	const [uploadedImage, setUploadedImage] = useState('');
	const [existingImageUrl, setExistingImageUrl] = useState(editedAddon?.posterImageUrl);

	useEffect(() => {
		restAxios.get('/versioning/releases').then((response) => {
			setVersions(response.data);
		});
	}, []);

	useEffect(() => {
		if (initialValues && initialValues.posterImageUrl) {
			setUploadedImage(initialValues.posterImageUrl);
		}
	}, [initialValues]);

	const formatSubmitData = (data) => {
		data = { 
			...data,
			posterImage: (uploadedImage && uploadedImage.length) ? (new RegExp('^(http|https)://', 'i').test(uploadedImage) ? null : uploadedImage) : null,
			posterImageUrl: (existingImageUrl && existingImageUrl.length) ? existingImageUrl : null
		};

		return data;
	};

	const submit = (values) => {
		let addonData = formatSubmitData(values);
		setSubmitButtonLoading(true);

		updateProductAddon(values.id, addonData)
			.then((updateResponse) => {
				const jobId = updateResponse?.data?.jobId;
				
				function pollJobStatus() {
					getJob(jobId)
						.then((jobResponse) => {
							if (jobResponse.data.status.toLowerCase() === 'succeeded' || jobResponse.data.status.toLowerCase() === 'failed') {
								afterUpdateCallback();
							} else {
								setTimeout(pollJobStatus, 1000);
							}
						})
						.catch((error) => {
							setTimeout(pollJobStatus, 1000);
						});
				}

				pollJobStatus();

				setSubmitButtonLoading(false);
				onHide();
				pushSuccessfulNotification('Add-on successfully updated.');
			})
			.catch((error) => {
				setSubmitButtonLoading(false);
				pushDangerNotification(error?.response?.data?.detail || error?.message);
			});
	};
	
	const cancelEditModal = () => {
		onHide();
	};

	return (
		<form id="product-addon" className="form modal fade">
			<Modal
				enforceFocus={false}
				show={true}
				onHide={onHide}
				content={<ModalBody uploadedImage={uploadedImage} setUploadedImage={setUploadedImage} setExistingImageUrl={setExistingImageUrl} versions={versions} change={change} editedAddon={editedAddon}/>}
				title="Edit add-on"
				size="lg"
				actions={{
					primary: {
						label: !submitButtonLoading ? 'Save' : 'Saving...',
						onClick: handleSubmit(submit),
						disabled: submitButtonLoading,
					},
					secondary: {
						label: 'Cancel',
						onClick: cancelEditModal,
						disabled: submitButtonLoading,
					},
				}}
			/>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		initialValues: state.productAddons.selectedAddon
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		pushSuccessfulNotification: (message) => {
			return dispatch(pushSuccessfulNotification(message));
		},
		pushDangerNotification: (message) => {
			return dispatch(pushDangerNotification(message));
		},
	};
};

const EditModalForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	validate: AddonValidationService.validate,
	onSubmit: submit,
	onSubmitFail: (errors, dispatch, submitError) => {
		if (errors) {
			dispatch(pushDangerNotification('Cannot have empty fields. Please check all the fields from all the tabs.'));
		}
	},
	shouldValidate: () => {
		return true;
	},
})(EditModal);

export default connect(mapStateToProps, mapDispatchToProps)(EditModalForm);

EditModal.propTypes = {
	afterUpdateCallback: PropTypes.func.isRequired,
};
