class AddonValidationService {
	static validate = (values) => {
		const errors = {};
		const fields = [
			'name',
			'translations.it.name',
			'translations.es.name',
			'summary',
			'translations.it.summary',
			'translations.es.summary',
			'description',
			'translations.it.description',
			'translations.es.description',
			'summary',
			'description',
			'maxPeriodYears',
			'code',
			'minProductVersion',
		];

		fields.forEach((field) => {
			const fieldValue = AddonValidationService.getNestedValue(values, field);
			if (AddonValidationService.validators.required(fieldValue)) {
				AddonValidationService.setNestedError(errors, field, 'Cannot be empty');
			}
		});

		if (values.addonTiers && values.addonTiers.length > 0) {
			const tiersArrayErrors = [];
			values.addonTiers.forEach((tier, index) => {
				const tierErrors = {};
				if (AddonValidationService.validators.required(tier.name)) {
					tierErrors.name = 'Name cannot be empty';
				}
				if (AddonValidationService.validators.required(tier.code)) {
					tierErrors.code = 'Code cannot be empty';
				}
				if (AddonValidationService.validators.required(tier.value)) {
					tierErrors.value = 'Value cannot be empty';
				} else if (!AddonValidationService.validators.positiveNumber(tier.value)) {
					tierErrors.value = 'Value must be a positive number';
				}
				if (Object.keys(tierErrors).length > 0) {
					tiersArrayErrors[index] = tierErrors;
				}
			});
			if (tiersArrayErrors.length > 0) {
				errors.addonTiers = tiersArrayErrors;
			}
		}

		return errors;
	};

	static getNestedValue(obj, path) {
		return path.split('.').reduce((acc, key) => { return (acc && acc[key] !== undefined ? acc[key] : undefined); }, obj);
	}

	static setNestedError(obj, path, message) {
		const keys = path.split('.');
		keys.reduce((acc, key, index) => {
			if (index === keys.length - 1) {
				acc[key] = message;
			} else {
				acc[key] = acc[key] || {};
			}

			return acc[key];
		}, obj);
	}

	static validators = {
		required: (value) => { return !value || value === undefined || value.length === 0; },
		positiveNumber: (value) => { return !isNaN(value) && value > 0; },
	};
}

export default AddonValidationService;
