import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Badge, Icon, Table, Tooltip } from '@syneto/compass-react';
import _ from 'lodash';
import DateFormatterService from '../../../../../helpers/DateFormatter';
import MaintenanceStatusFormatter from '../../../../../helpers/MaintenanceStatusFormatter';
import OSVersionParserService from '../../../../../helpers/OSVersionParser';
import GenerateDownloadLinkForOSManualService from '../../../../../helpers/GenerateDownloadLinkForOSManual';
import { loadProductMaintenanceServiceDetails } from '../../../../../data/productMaintenanceService/actions';
import ProductsTableSearchResults from './ProductsTableSearchResults';

const ProductsTable = (props) => {
	const { maintenanceServices, loadProductMaintenanceServiceDetails, pagination, setProductsQueryParams, productsQueryParams } = props;
	const [selectedProduct, setSelectedProduct] = useState(null);

	useEffect(() => {
		setSelectedProduct(null);
	}, [productsQueryParams]);

	const handlePagination = (page, sizePerPage) => {
		const start = (page - 1) * sizePerPage;

		setProductsQueryParams({
			...productsQueryParams,
			start: start >= 0 ? start : 0,
			limit: sizePerPage
		});
	};

	const handleTableChange = (type, { page, sizePerPage, searchText, sortOrder, sortField, data }) => {
		switch (type) {
			case 'pagination': {

				handlePagination(page, sizePerPage);
				return;
			}
		}
	};

	const renderPremium = (cell, row) => {
		if ( row.maintenanceService.plan.synetoInternalName.toLowerCase().includes('premium') ) {
			return (
				<Tooltip placement="top" text="Premium">
					<span>
						<Icon name="fa fa-star" className="text-warning"/>
					</span>					
				</Tooltip>
			);
		}

		return null;
	};

	const renderNotes = (cell, row) => {
		if ( row.notes ) {
			return (
				<Tooltip placement="top" text={ row.notes }>
					<span>
						<Icon name="fa fa-comment-o" aria-hidden="true"/>
					</span>
				</Tooltip>
			);
		}

		return null;
	};

	const renderStatus = (cell, row) => {
		return (
			<Tooltip placement="top" text={ MaintenanceStatusFormatter.setTooltipInformation(row.maintenanceService.expiresOn) }>
				<Badge
					appearance={ MaintenanceStatusFormatter.setStyle(MaintenanceStatusFormatter.maintenanceStatus(row.maintenanceService)) }
				>
					{ MaintenanceStatusFormatter.maintenanceStatus(row.maintenanceService) }
				</Badge>
			</Tooltip>
		);
	};

	const renderLastDayActive = (cell, row) => { return row.maintenanceService.expiresOn ? DateFormatterService.formatDateToSpecificFormat(row.maintenanceService, 'expiresOn', 'DD-MM-YYYY') : 'N/A'; };
	const renderSerialNumber = (cell, row) => { return _.get(row, 'serialNumber', 'N/A'); };
	const renderOs = (cell, row) => { return row.osVersion ? GenerateDownloadLinkForOSManualService.generateDownloadLink(OSVersionParserService.parseOSVersion(row.osVersion)) : 'N/A'; };
	const renderHardwareId = (cell, row) => { return _.get(row, 'hardwareId') ? row.hardwareId : 'No hardware ID'; };
	const renderModel = (cell, row) => { return _.get(row, 'model.title') ? row.model.title : 'No model'; };
	// const renderCustomer = () => { return 'Customer not available'; };

	if (!productsQueryParams) { return null; }

	return (
		<div>
			<Table
				data={ maintenanceServices }
				keyField="id"
				scrollable
				remote
				selectable="single"
				onSelect={ ({ item }) => { setSelectedProduct(item); loadProductMaintenanceServiceDetails(item.maintenanceService.id); } }
				pagination={{
					sizePerPageList: [15, 30, 60, 100, 200],
					sizePerPage: pagination?.itemsPerPage,
					showTotal: true,
					page: pagination?.currentPage,
					totalSize: pagination?.totalItems
				}}
				onTableChange={handleTableChange}
			>
				<Table.Col field="status" renderCell={ renderStatus }>Status</Table.Col>
				<Table.Col field="lastDayActive" renderCell={ renderLastDayActive }>Last day active</Table.Col>
				<Table.Col field="serialNumber" renderCell={ renderSerialNumber }>Serial number</Table.Col>
				<Table.Col field="os" renderCell={ renderOs }>OS</Table.Col>
				<Table.Col field="hardwareId" renderCell={ renderHardwareId }>Hardware ID</Table.Col>
				<Table.Col field="model" renderCell={ renderModel }>Model</Table.Col>
				{/* <Table.Col field="customer" renderCell={ renderCustomer }>Customer</Table.Col> */}
				<Table.Col field="premium" align="left" width="30px" renderCell={ renderPremium }/>
				<Table.Col field="notes" align="left" width="30px" renderCell={ renderNotes }/>
			</Table>
			{!selectedProduct ? null :
				<ProductsTableSearchResults selectedProduct={selectedProduct} query={ productsQueryParams }/>
			}
		</div>
	);
};


const mapStateToProps = (state) => {
	return {
		products: state.products.products,
		pagination: state.products.pagination,
		loading: state.products.loading
	};
};

ProductsTable.propTypes = {
	maintenanceServices: PropTypes.array.isRequired,
	pagination: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	loadProductMaintenanceServiceDetails
})(ProductsTable);

