import React, { useEffect, useState } from 'react';
import { unflatten } from 'flat';
import Form from '../../../../components/forms/form';
import Validator from '../../../../components/forms/validate';
import { Button } from '@syneto/compass-react';
import { reduxForm } from 'redux-form';
import { getUpgradeToOsSettings, patchUpgradeToOsSettings } from '../../../../services/settings';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

let submitButtonDisabled = false;

const validate = (values) => {
	const validator = new Validator(values, {
		'migrationGuide': ['url'],
		'os5OvaFile': ['url'],
		'surveyTool': ['url']
	});

	if (validator.fails()) {
		submitButtonDisabled = true;
		return unflatten(validator.errors.all());
	} else {
		submitButtonDisabled = false;
		return {};
	}
};

let AdminSettingsUpgradeToOsTab = (props) => {
	const { activeKey, handleSubmit, reset } = props;
	const [initialValues, setInitialValues] = useState(null);

	useEffect(() => {
		if (activeKey === 'upgradeToOs5') {
			getUpgradeToOsSettings()
				.then((response) => {
					setInitialValues(response.data);
				})
				.catch(() => {
					setInitialValues(null);
				});
		}
		reset('upgradeToOs5');
	}, [activeKey, reset]);

	const submit = (formValues) => {
		patchUpgradeToOsSettings(formValues)
			.then((response) => {
				setInitialValues(response.data);
				props.dispatch(pushSuccessfulNotification('Settings updated'));
			})
			.catch((error) => {
				setInitialValues(initialValues);
				props.dispatch(pushDangerNotification(error?.response?.data?.detail ?? 'Could not update settings.'));
			});
	};

	if (!initialValues) {
		return <div>Loading...</div>;
	}

	return (
		<form className="mb-5">
			<h1 className="mb-1">Upgrade to OS5 distribution links</h1>
			<Form.text name="os5OvaFile" label="Syneto OS5 Ova file:" />
			<Form.text name="surveyTool" label="Survey tool for OS5:" />
			<Form.text name="migrationGuide" label="OS4 to OS5 Migration guide:" />
			<Button onClick={handleSubmit(submit)} type="submit" disabled={submitButtonDisabled}>Save</Button>
		</form>
	);
};

AdminSettingsUpgradeToOsTab = reduxForm({
	form: 'upgradeToOs5',
	enableReinitialize: true,
	validate: validate
})(AdminSettingsUpgradeToOsTab);

export default (props) => {
	const [initialValues, setInitialValues] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (props.activeKey === 'upgradeToOs5') {
			getUpgradeToOsSettings()
				.then((response) => {
					setInitialValues(response.data);
				})
				.catch((error) => {
					setInitialValues(null);
					setError(error);
				});
		}
	}, [props.activeKey]);

	if (!initialValues) {
		if (error) {
			console.log(error);
			return <div>Could not load page. Error: {error.message}<Button role="link" className="ms-2" onClick={() => {
				getUpgradeToOsSettings()
					.then((response) => {
						setInitialValues(response.data);
					})
					.catch((error) => {
						setInitialValues(null);
						setError(error);
					});
			}}>Try again.</Button></div>;
		}

		return <div>Loading...</div>;
	}

	return <AdminSettingsUpgradeToOsTab {...props} initialValues={initialValues} />;
};
